import * as React from "react";

// markup
const IndexPage = () => {
  return (
    <div
      style={{
        height: "98vh",
        width: "100%",
        background: "#",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ fontSize: "72px", fontFamily: "Arial" }}>
        The year is {new Date().getFullYear()}
      </h1>
    </div>
  );
};

export default IndexPage;
